import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';

export interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  items: Array<{ key: number | string; value?: string; text?: React.ReactNode | string }>;
  activeTab?: number | string;
  useValueAsKey?: boolean;
  mobile?: boolean;
  large?: boolean;
  onSelect?: (event: any, value?: any) => void;
}

const Tabs: React.FC<TabsProps> = ({
  className,
  centered,
  items,
  large,
  activeTab,
  useValueAsKey = false,
  onSelect,
  mobile,
  ...rest
}) => {
  if (!Array.isArray(items)) return null;
  return (
    <TabsContainer className={clsx('tabs', { centered, mobile, large }, className)} {...rest}>
      {items.map((item, idx) => {
        const key = useValueAsKey ? item.value : item.key || idx;
        const active = activeTab === key;

        return (
          <TabItem
            data-cy={`button_tab_${key}`}
            key={key}
            className={clsx('tab-item', { active })}
            onClick={e => onSelect && onSelect(e, item)}>
            {item.text}
          </TabItem>
        );
      })}
    </TabsContainer>
  );
};

export default Tabs;

const TabsContainer = styled.div`
  display: flex;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 4px;
  flex-wrap: wrap;

  &.centered {
    justify-content: center;

    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.mobile {
    padding: 0;

    .tab-item {
      margin-right: 0;
      padding: 8px;
      flex: 1;
      line-height: 16px;
      font-weight: 600;
    }
  }
  &.large {
    background: none;
    .tab-item {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);
      border-bottom: 2px solid transparent;
      border-radius: 0;
      padding: 0;
      &.active {
        background: none;
        border-bottom: 2px solid ${props => props.theme.colors.red};
        color: black;
      }
    }
  }
`;

const TabItem = styled.button<any>`
  /** define a proper type for theme object here */
  background: transparent;
  border: 0;
  outline: none;
  padding: 6px 8px;
  margin-right: 12px;
  font-family: Inter, sans-serif;
  font-weight: normal;
  font-size: 12px;
  color: #000;
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 3px;

  .icon {
    margin-right: 6px;
    fill: #000;
  }

  &.active {
    background-color: #000;
    color: #fff;

    .icon {
      fill: #fff;
    }
  }
  &:last-child {
    margin-right: 0px;
  }
`;
