import React from 'react';

const NoSSR = ({ children, ...props }) => {
  if (typeof window === 'undefined') return null;
  return (
    <React.Suspense fallback={<div style={{ padding: 10 }}>Loading...</div>} {...props}>
      {children}
    </React.Suspense>
  );
};

export default NoSSR;
